<p-dialog header="Create Adobe User for {{userData.fullName}} " [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <div>
        <div class="container-fluid">
            <div class="form-group row">
                <div class="col-sm">
                
                </div>
            </div>
            
        </div>
    </div>
        <ng-template pTemplate="footer">
            <button type="button" class="btn btn-secondary" (click)="closeDisplay()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)='createAdobeUser()'>Add</button>
        </ng-template>
</p-dialog>
