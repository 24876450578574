import { Component, OnInit } from '@angular/core';
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { utilities } from 'src/app/utilities/utilities';
import { NgForm,FormGroup } from "@angular/forms";
import { googleInfoRepository } from 'src/app/Repositories/googleInfo.repository';
import { GoogleUserDataService } from 'src/app/dataservices/google-user-dataservice';
import { adobeReopsitory } from 'src/app/Repositories/adobe.repository';



@Component({
  selector: 'user-create.page',
  templateUrl: './user-create.page.html',
  styleUrls: ['./user-create.page.css']
})
export class UserCreatePageComponent implements OnInit {
  newUser = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    domain: "",
    description: "",
    assignAdobe: false,
    assignLicense: false,
    licenseType: ""
  }
  displayCredentials = false;

  private _domains: any;
 

  constructor(
    private _clientRepository: ClientRepository,
    private _googleInfoRepository: googleInfoRepository,
    private _googleUserDataService: GoogleUserDataService,
    private _adobeRepo: adobeReopsitory,
    private util: utilities
  ) { }

  ngOnInit(): void {
    // so easy... set the domain in the model to the first domain in the domains list 
    // and the select will show that ! Do it the angular way. I hope this works when we 
    //load it from google ??? DOESN"T WORK!
   // this.newUser.domain = this.domains[0];
    this.util.displayMessageToUser("Create a user!");
    this._googleInfoRepository.getFromServer(this._clientRepository.getCurrentClientName());
    //this.domains = this._googleInfoRepository.getDomains();
    this.displayCredentials = false;
    

  }

  get domains() {  
    // getting the default domain was a good idea, but it reverts back to it as soon as the select looses focus :(
   // this.newUser.domain = this._googleInfoRepository.getPrimaryDomainName();
    return this._googleInfoRepository.getDomains();
  }



  createUserName() {
    this.newUser.userName = this.newUser.firstName.toLowerCase() + "." + this.newUser.lastName.toLowerCase();
  }


  get manageAdobe() {
  
    return this._clientRepository.getManageAdobe();
  }

  get adobeLicenses() {
    return ['Creative Cloud'];
  }

  submitForm(form: NgForm) { 
    this.newUser.email = this.newUser.userName + "@" + this.newUser.domain;
    
    
     // generate password:
     this.newUser.password = this.util.generatePassWord(7);

    // configure the obj to send:
    let sendObj: any = new Object;
    sendObj.client = this._clientRepository.getCurrentClientName();
    sendObj.usejc = this._clientRepository.getCurrentClient().usejc;
    sendObj.allStaffGroup= this._clientRepository.getCurrentClient().jcAllStaffGroup;
    sendObj.user = this.newUser;
    // looks like we are good to go !

 
    
    
    try {
      this._googleUserDataService.addUser(sendObj).subscribe( response => 
        {
          console.log(response);
          let clipboardString = "Name: " + this.newUser.firstName + " " + this.newUser.lastName +"\r\n";
          clipboardString += "User Name: " + this.newUser.userName + "\r\n" ;
          clipboardString += "Email: " + this.newUser.email + "\r\n" ;
          clipboardString += "Password: " + this.newUser.password + "\r\n" ;

           
          this.displayCredentials = true;
          this.util.copyToClipBoard(clipboardString);
          this.util.displayMessageToUser( "User " + this.newUser.firstName + " " + this.newUser.lastName + " has been created and the information above has been copied to the clipboard");

          // use a delay to call the reset password function to get round the google delay
          // however we need the create user fucntion to return the jumpcloud id if jumpcloud is in use.
          
        //   setTimeout(() => {
        //     this._
        //  }, 2000);
        })

        if (this.newUser.assignAdobe) {
          // we need to create the adobe user here.
          
          let adobeUser = this._adobeRepo.createAdobeID(sendObj.client, this.newUser.email, this.newUser.firstName, this.newUser.lastName);
          console.log(adobeUser);
        }
     

    } catch(err) {
      this.util.debug (err);
    }

   

  }

  selectDomain(value:string) {
    console.log(value)
    this.newUser.domain  = value;
  }


  trackByFn(i: number) { 
    return i
  }
}
