import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserRepository } from 'src/app/Repositories/user.repository';
import { utilities } from 'src/app/utilities/utilities';
import Modal from "bootstrap/js/src/modal"
import { ClientRepository } from 'src/app/Repositories/client.repository';
import { adobeReopsitory } from 'src/app/Repositories/adobe.repository';
import { listUser } from 'src/app/models/googleUser.model';

@Component({
  selector: 'app-create-adobe-user',
  templateUrl: './create-adobe-user.component.html',
  styleUrls: ['./create-adobe-user.component.css']
})
export class CreateAdobeUserComponent implements OnInit {
  @Input() userData: listUser;
  @Input() displayModal: boolean;
  @Output() resetDisplay: EventEmitter<any> = new EventEmitter();


  displayMe: boolean
  creteAdobeUserModal: Modal;
  

  constructor(
        private util: utilities,
        private _userRepo: UserRepository,
        private _clientRepo: ClientRepository,
        private _adobeRepository: adobeReopsitory
  ) { }

  ngOnInit(): void {
  }

  createAdobeUser() {
    let nameArray = this.userData.fullName.split(" ");
    let adobeUser = this._adobeRepository.createAdobeID(this._clientRepo.getCurrentClientName(), this.userData.primaryEmail, nameArray[0], nameArray[1]);
    this.closeDisplay();
    console.log(adobeUser);
  }

  assignLicense() {
    
  }

  closeDisplay() {
    // tells the parent to close this display ... very clever ;)
    this.resetDisplay.emit(false);
  }
}
